import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "textarea", "button"];

  checkValid(event) {
    if (!this.formIsValid()) {
      event.preventDefault();
    }
  }

  submit(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (this.formIsValid() && !this.buttonTarget.disabled) {
        this.element.requestSubmit();
      }
    }
  }

  formIsValid() {
    return this.textareaTarget.value.trim().length > 0 || this.formHasImages();
  }

  formHasImages() {
    return this.formTarget.querySelectorAll("input[name*='images_attributes']").length > 0;
  }

  reset() {
    this.element.reset();
    this.textareaTarget.focus();
    this.textareaTarget.dispatchEvent(new Event("input", { bubbles: true })); // trigger autogrow
  }
}
