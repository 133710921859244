import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  reset() {
    // Get checked option inside the element
    const selectedChoice = this.element.querySelector("input[type='radio']:checked");
    if (!selectedChoice) return;
    selectedChoice.checked = false;
  }
}
