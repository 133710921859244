import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "checkbox"];

  connect() {
    this.titleTarget.addEventListener("dragover", this.handleDragOver.bind(this));
    this.titleTarget.addEventListener("dragleave", this.handleDragLeave.bind(this));
  }

  handleDragOver(event) {
    const checkbox = this.checkboxTarget;
    if (checkbox && !checkbox.checked && !this.dragTimeout) {
      this.dragTimeout = setTimeout(() => {
        checkbox.click();
        this.dragTimeout = null;
      }, 500);
    }
  }

  handleDragLeave(event) {
    if (this.dragTimeout) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = null;
    }
  }

  handleCheck(event) {
    if (event.target.checked) {
      this.element.classList.add("is-expanded");
    } else {
      this.element.classList.remove("is-expanded");
    }
  }
}
