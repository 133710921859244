import Sortable from "@stimulus-components/sortable";
import { patch } from "@rails/request.js";

export default class extends Sortable {
  // Add some more options that can be passed to the Sortable instance
  static values = {
    ...Sortable.values,
    group: { type: Object, default: undefined },
    sort: { type: Boolean, default: true },
    // For updating the list that an item belongs to
    listId: { type: String, default: undefined },
    listParamName: { type: String, default: "list_id" },
  };

  // Called when an item is added to a new list
  async onAdd({ item, newIndex, to }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const data = new FormData();

    const positionParam = this.resourceNameValue
      ? `${this.resourceNameValue}[${this.paramNameValue}]`
      : this.paramNameValue;
    data.append(positionParam, newIndex + 1);

    const listIdParam = this.resourceNameValue
      ? `${this.resourceNameValue}[${this.listParamNameValue}]`
      : this.listParamNameValue;
    data.append(listIdParam, this.listIdValue);

    return await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue });
  }

  get options() {
    return {
      ...super.options,
      group: this.groupValue,
      sort: this.sortValue,
      onAdd: this.onAdd.bind(this),
    };
  }

  get defaultOptions() {
    return {
      ...super.defaultOptions,
      delayOnTouchOnly: true,
      delay: 100,
    };
  }
}
