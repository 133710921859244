import { Controller } from "@hotwired/stimulus";

let uppyModule;

export default class extends Controller {
  static targets = ["dropzone", "thumbnail", "uploadButton", "form", "file"];
  static values = {
    formFieldPrefix: String,
    allowedFileTypes: Array,
  };

  initialize() {
    this.numPendingRequests = 0;

    uppyModule = import("~/lib/uppy");
  }

  async createUppy() {
    const Uppy = (await uppyModule).uppyInstance;
    const uploadedFileData = (await uppyModule).uploadedFileData;
    const nanoid = (await uppyModule).nanoid;
    const formFieldPrefix = this.formFieldPrefixValue;

    this.uppy = new Uppy({
      id: this.dropzoneTarget.id,
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: this.allowedFileTypesValue,
      },
      dropTarget: this.dropzoneTarget,
      statusBarTarget: this.statusBarTarget,
    });

    this.uppy.on("thumbnail:generated", (file, preview) => {
      this.addThumbnail(file, preview);
    });

    this.uppy.on("upload", (data) => {
      this.numPendingRequests += data.fileIDs.length;
      this.uploadButtonTarget.disabled = true;
      this.uploadButtonTarget.classList.add("du-btn-disabled");
    });

    const hiddenFieldContainerId = "fileUploadHiddenFields";
    this.hiddenFieldContainer = document.getElementById(hiddenFieldContainerId);

    if (!this.hiddenFieldContainer) {
      this.hiddenFieldContainer = document.createElement("div");
      this.hiddenFieldContainer.id = hiddenFieldContainerId;
      this.formTarget.appendChild(this.hiddenFieldContainer);
    }

    this.uppy.on("upload-success", (file, _response) => {
      const id = nanoid();
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";

      // Use different attribute name based on file type
      const attributeName = file.type === "application/pdf" ? "documents" : "images";
      hiddenField.name = `${formFieldPrefix}[${attributeName}_attributes][${id}][file]`;

      hiddenField.value = uploadedFileData(file);
      this.hiddenFieldContainer.appendChild(hiddenField);

      if (file.type === "application/pdf") {
        this.addPdfPreview(file);
      }

      this.numPendingRequests -= 1;
      if (this.numPendingRequests === 0) {
        this.uploadButtonTarget.disabled = false;
        this.uploadButtonTarget.classList.remove("du-btn-disabled");
      }
    });

    this.formTarget.addEventListener("turbo:submit-end", () => {
      this.uppy.cancelAll();
      this.formTarget.reset();
      this.thumbnailTarget.classList.add("hidden");
      this.thumbnailTarget.innerHTML = "";
      this.hiddenFieldContainer.innerHTML = "";
    });

    if (this.fileTarget) {
      this.fileTarget.addEventListener("change", (event) => {
        const files = Array.from(event.target.files);

        files.forEach((file) => {
          try {
            this.uppy.addFile({
              source: "file input",
              name: file.name,
              type: file.type,
              data: file,
            });
          } catch (err) {
            if (err.isRestriction) {
              // handle restrictions
              console.log("Restriction error:", err);
            } else {
              // handle other errors
              console.error(err);
            }
          }
        });
      });

      this.uppy.on("file-removed", () => {
        this.fileTarget.value = null;
      });

      this.uppy.on("complete", () => {
        this.fileTarget.value = null;
      });
    }
  }

  addThumbnail(file, thumbnail) {
    const image = new Image();
    image.src = thumbnail;
    image.classList.add("max-h-40");
    this.thumbnailTarget.appendChild(image);
    this.thumbnailTarget.classList.remove("hidden");
  }

  addPdfPreview(file) {
    const container = document.createElement("div");
    container.classList.add("flex", "h-40", "rounded", "bg-base-200", "p-4");

    const iconAndFilename = document.createElement("div");
    iconAndFilename.classList.add("flex", "items-center", "gap-2");

    iconAndFilename.innerHTML = `
    <svg class="w-4 h-4 p-2 sm:p-0 svg-inline--fa" type="regular" viewBox="0 0 512 512">
      <use href="#solid-file-pdf"></use>
    </svg>
    <span>${file.name}</span>
    `;

    container.appendChild(iconAndFilename);

    this.thumbnailTarget.appendChild(container);
    this.thumbnailTarget.classList.remove("hidden");
  }

  async connect() {
    // If there's no form, don't do anything. The message input sometimes doesn't have a form when it's shared
    // read-only.
    if (this.hasFormTarget) {
      await this.createUppy();
    }
  }

  openDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileTarget.click();
  }
}
