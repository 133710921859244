import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = {
		anchor: String,
	};

	static targets = ["modal"];

	connect() {
		if (this.anchorValue && window.location.hash === this.anchorValue) {
			this.open();
		}
	}

	open() {
		this.modalTarget.showModal();
		this.setHash();
	}

	setHash() {
		if (!this.anchorValue || window.location.hash === this.anchorValue) {
			return;
		}

		const newUrl = new URL(window.location);
		newUrl.hash = this.anchorValue;
		history.pushState({}, "", newUrl);
	}

	hashChange(event) {
		const newUrl = new URL(event.newURL);
		if (this.anchorValue && newUrl.hash === this.anchorValue) {
			this.open();
		}
	}

	handleClose() {
		if (this.anchorValue && window.location.hash === this.anchorValue) {
			// Remove the hash from the URL
			history.pushState({}, "", window.location.pathname + window.location.search);
		}
	}

	handleFormSubmit(event) {
		if (event.detail.success) {
			this.modalTarget.close();
		}
	}
}
