import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

// Connects to data-controller="browser-session-viewer"
export default class extends Controller {
  static values = {
    id: String,
    status: String,
  };

  static targets = ["iframe"];

  iframeTargetConnected() {
    this.updateViewportToMatchIframe();
  }

  updateViewportToMatchIframe() {
    this.updateViewport(this.iframeTarget.clientWidth, this.iframeTarget.clientHeight, window.devicePixelRatio);
  }

  storageKey() {
    return `browserSessionViewport-${this.idValue}`;
  }

  updateViewport(width, height, scale_factor) {
    return false; // TODO: disable for now, so that UseBrowser doesn't get messed up

    if (this.statusValue !== "running") {
      return;
    }

    const { lastWidth, lastHeight, lastScaleFactor } = JSON.parse(
      window.localStorage.getItem(this.storageKey()) || "{}",
    );

    if (width === lastWidth && height === lastHeight && scale_factor === lastScaleFactor) {
      return;
    }

    patch(`/browser_sessions/${this.idValue}/update_viewport`, {
      responseKind: "turbo-stream",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        browser_session: {
          width,
          height,
          scale_factor,
        },
      }),
    });

    window.localStorage.setItem(
      this.storageKey(),
      JSON.stringify({ lastWidth: width, lastHeight: height, lastScaleFactor: scale_factor }),
    );
  }

  handleResize(event) {
    this.updateViewportToMatchIframe();
  }
}
