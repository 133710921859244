import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	input(event) {
		// Delete the hidden input to avoid sending two URL parameters
		if (event.target.checked) {
			this.element.querySelector('input[type="hidden"]').remove();
		}
		this.element.requestSubmit();
	}
}
