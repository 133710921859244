import { Turbo } from "@hotwired/turbo-rails";

function isElementInViewport(el) {
  const container = el.closest("[data-turbo-scroll-container]");

  const elRect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    elRect.top >= containerRect.top &&
    elRect.left >= containerRect.left &&
    elRect.bottom <= containerRect.bottom &&
    elRect.right <= containerRect.right
  );
}

Turbo.StreamActions.scroll_to = function () {
  const target = this.targetElements[0];
  if (isElementInViewport(target)) {
    return;
  }
  target.scrollIntoView({ behavior: "smooth" });
};

Turbo.StreamActions.add_class = function () {
  const target = this.targetElements[0];
  target.classList.add(this.getAttribute("class"));
};

Turbo.StreamActions.remove_class = function () {
  const target = this.targetElements[0];
  target.classList.remove(this.getAttribute("class"));
};

Turbo.StreamActions.check_checkbox = function () {
  const target = this.targetElements[0];
  target.checked = true;
};
