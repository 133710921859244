import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];
  static values = { current: String };

  inputTargetConnected(element) {
    if (this.hasCurrentValue) {
      // TODO: Should be earlier of current date and current value, but we want to allow editing other fields than the
      // start_time and leaving start_time alone if it's in the past
      // Format: YYYY-MM-DDThh:mm:ss (no timezone, since it's a local time input)
      element.min = this.currentValue.slice(0, 16);
    } else {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // Convert to ISO string, slice off the seconds and timezone
      element.min = now.toISOString().slice(0, 16);
    }
  }
}
