import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.add("transition-opacity", "ease-in-out", "duration-[2000ms]");
    setTimeout(() => {
      this.element.classList.add("opacity-0");
    }, 5000);
    setTimeout(() => {
      this.close();
    }, 6500);
  }
  close() {
    this.element.remove();
  }
}
