import AutoSubmit from "@stimulus-components/auto-submit";

export default class extends AutoSubmit {
  static values = {
    delay: {
      type: Number,
      default: 100,
    },
  };

  static targets = ["dropdown"];

  submit() {
    super.submit();
    this.dropdownTarget.blur();
    this.dropdownTarget.classList.add(
      "border-green-500",
      "ring-green-300",
      "ring-2",
      "focus:border-green-500",
      "focus:ring-green-300",
    );
    setTimeout(() => {
      this.dropdownTarget.classList.remove(
        "border-green-500",
        "ring-green-300",
        "ring-2",
        "focus:border-green-500",
        "focus:ring-green-300",
      );
    }, 1200);
  }
}
