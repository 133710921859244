import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

/* global Missive -- Missive defined in missive integration script loaded in missive template*/

// Connects to data-controller="missive-integration"
export default class extends Controller {
  initialize() {
    const querystringParams = new URLSearchParams(window.location.search);
    this.iframeToken = querystringParams.get("token");
  }

  connect() {
    this.fetchConversations(Missive.state.conversations);
    this.onConversationChange();
  }

  onConversationChange() {
    Missive.on("change:conversations", (ids) => {
      this.fetchConversations(ids);
    });
  }

  fetchConversations(ids) {
    Missive.fetchConversations(ids).then((conversations) => {
      var emails = conversations
        .map((conversation) => {
          return conversation.messages
            .map((message) => {
              let results = message.reply_to_fields?.map((f) => f.address)?.flat() || [];
              results = results.concat(conversation.messages[0].to_fields.map((f) => f.address));
              results.push(message.from_field.address);
              return results;
            })
            .flat();
        })
        .flat();
      var encodedEmails = encodeURIComponent([...new Set(emails)].join(","));
      get(`/admin/missive_integration/?token=${this.iframeToken}&emails=${encodedEmails}`, {
        responseKind: "turbo-stream",
      });
    });
  }
}
