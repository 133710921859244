import { Controller } from "@hotwired/stimulus";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";

export default class extends Controller {
  connect() {
    const events = JSON.parse(this.element.dataset["events"]);
    new rrwebPlayer({
      target: this.element,
      props: {
        events,
      },
    });
  }
}
