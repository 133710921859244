import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import AutoSubmit from "@stimulus-components/auto-submit";
import Clipboard from "@stimulus-components/clipboard";
import RevealController from "@stimulus-components/reveal";
import TextareaAutogrow from "stimulus-textarea-autogrow";

const application = Application.start();
application.register("auto-submit", AutoSubmit);
application.register("clipboard", Clipboard);
application.register("reveal", RevealController);
application.register("textarea-autogrow", TextareaAutogrow);

const controllers = import.meta.glob("./**/*_controller.js", { eager: true });
registerControllers(application, controllers);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
