import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToLastMessage();
  }

  scrollToLastMessage() {
    const messages = this.element.querySelectorAll(".message");
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      lastMessage.scrollIntoView();
    }
  }
}
