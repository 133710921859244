import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["form"];

	connect() {
		let paramField = this.formTarget.querySelector(`input[name=${this.csrfParam()}]`);
		if (paramField) {
			return;
		}

		paramField = document.createElement("input");
		paramField.name = this.csrfParam();
		paramField.type = "hidden";
		paramField.value = this.csrfToken();

		this.formTarget.appendChild(paramField);
	}

	// The following 2 functions are extracted from "@rails/ujs"
	// https://github.com/rails/rails/blob/e5379fc5da4f70995e6f998eca0a52ff0a8ead2c/actionview/app/assets/javascripts/rails-ujs.js#L58-L65
	csrfToken() {
		const meta = document.querySelector("meta[name=csrf-token]");
		return meta && meta.content;
	}

	csrfParam() {
		const meta = document.querySelector("meta[name=csrf-param]");
		return meta && meta.content;
	}
}
