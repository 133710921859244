import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
	update(event) {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set("limit", event.target.value);
		Turbo.visit(window.location.pathname + "?" + urlParams);
	}
}
