import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["time"];
	static values = {
		formatOptions: { type: Object, default: { timeStyle: "short" } },
	};

	connect() {
		const formatter = new Intl.DateTimeFormat(undefined, this.formatOptionsValue);
		const timeValue = new Date(this.timeTarget.dateTime);
		const formatted = formatter.format(timeValue);
		this.timeTarget.textContent = formatted;
		this.timeTarget.title = timeValue;
	}
}
